import Masonry from 'masonry-layout';

const masonry = (config = {}) => ({
    args: config.args,
    init() {
        // FFR: If you're trying to fix the masonry item height bug when loading the page, this is not the problem.
        // However, the problem is that the images width/height attributes are not set in local environment.
        this.$nextTick(() => {
            this.$el.masonry = new Masonry(this.$el, this.args);
        });
    },
});

export { masonry as default };
